import React, { useState, useEffect } from "react";
import useFormValidation from "../hooks/Form-validation";
import ContactForm from "../components/forms/ContactForm";
import { CONTACT_INITIAL_STATE } from "../constants/Initial-State";
import { createData } from "../firebase/database";
import UploadingModal from "../components/modal/Uploading";
import { v4 as uuid } from "uuid";
import { CONTACT } from "../constants";
import { FaPhoneAlt, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaFacebookF, FaLinkedin } from "react-icons/fa6";
import Button from "../components/UI/Button";

import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();
logEvent(analytics, "Contact page");

function Contact() {
  const [isUploading, setIsUploading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false); // for button error

  const handleFormSubmit = async (formValues) => {
    const id = uuid();
    setIsError(false);
    setIsModal(true);
    setIsSubmit(false);
    setIsUploading(true);

    const result = await createData(formValues, "Contact-List", id);
    // TODO error handling
    if (result) {
      setIsUploading(false);
      resetForm();
    } else {
      setIsUploading(false);

      setIsError(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    resetForm,
  } = useFormValidation(CONTACT_INITIAL_STATE, handleFormSubmit);

  const submitForm = (event) => {
    event.preventDefault();
    setIsSubmit(true);
    handleSubmit(event);
  };
  const handleClose = () => {
    setIsModal(false);
  };

  return (
    <>
      {isModal && (
        <UploadingModal
          onClose={handleClose}
          uploadStatus={isUploading}
          error={isError}
        />
      )}
      <div
        className="lg:mx-60 lg:px-6 flex flex-col items-center justify-center py-8 mx-4 mb-6 bg-white rounded-lg shadow-xl"
        style={{
          background:
            "linear-gradient(60deg, rgba(69, 235, 175, 0.3) 0%, rgba(37, 153, 229, 0.3) 100%, rgba(0, 25, 145, 0.3) 100%)",
        }}>
        <h2 className="lg:text-4xl mb-6 font-serif text-3xl font-medium text-center text-gray-800">
          Get in Touch
        </h2>
        <div className="flex flex-col space-y-3">
          <a
            href={`tel:${CONTACT.phoneNumber1}`}
            className="hover:text-hover flex items-center space-x-2 text-gray-800">
            <FaPhoneAlt className="text-lg" />
            <span>Call Us: {CONTACT.phoneNumber1}</span>
          </a>
          <a
            href={`tel:${CONTACT.phoneNumber2}`}
            className="hover:text-hover flex ml-[85px] items-center space-x-2 text-gray-800">
            <span>{CONTACT.phoneNumber2}</span>
          </a>
          <a
            href={`mailto:${CONTACT.email1}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-hover flex items-center space-x-2 text-gray-800">
            <MdEmail className="text-lg" />
            <span>Email: {CONTACT.email1}</span>
          </a>
          <a
            href={`mailto:${CONTACT.email}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-hover text-end ml-[74px] flex items-center w-full text-gray-800">
            <span className=""> {CONTACT.email}</span>
          </a>
        </div>
        <div className="flex justify-center mt-6 space-x-4">
          <a
            href={CONTACT.instagram}
            target="_blank"
            className="bg-secondary hover:scale-90 p-2 text-white transition-transform duration-200 transform scale-75 rounded-full">
            <FaInstagram className="text-2xl" />
          </a>
          <a
            href={CONTACT.facebook}
            target="_blank"
            className="bg-secondary hover:scale-90 p-2 text-white transition-transform duration-200 transform scale-75 rounded-full">
            <FaFacebookF className="text-2xl" />
          </a>
          {/* <a
            href={CONTACT.linkedin}
            className="bg-secondary hover:scale-90 p-2 text-white transition-transform duration-200 transform scale-75 rounded-full">
            <FaLinkedin className="text-2xl" />
          </a> */}
        </div>
      </div>
      <div className="lg:mx-60 mx-4 mb-5">
        <form onSubmit={submitForm} className="px-1">
          <h3 className=" mb-4 text-xl font-bold">Send your message</h3>
          <ContactForm
            handleChange={handleChange}
            values={values}
            errors={errors}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
          <label className="text-grey-darker block mb-2 text-sm font-bold">
            Description:
          </label>
          <textarea
            name="description"
            placeholder="Enter description"
            onChange={handleChange}
            maxLength={500}
            value={values.description}
            className="text-grey-darker focus:outline-none focus:shadow-[#000E66] focus:shadow-md w-full mt-0 mb-0 px-3 py-2 leading-tight border rounded shadow appearance-none"
          />
          {errors.description && (
            <p className="mb-4 -mt-1 text-sm italic text-red-500">
              {errors.description}
            </p>
          )}
          <div className="flex justify-center mt-4">
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </div>{" "}
          {Object.keys(errors).length !== 0 && isSubmit && (
            <p className="mt-1 italic font-medium text-center text-red-500">
              Please fill all the required fields.
            </p>
          )}
        </form>
      </div>
    </>
  );
}

export default Contact;
